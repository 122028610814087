import moment from "moment";
import { useNavigate } from "react-router-dom";
import { calculate } from "../apollo";
import imageBox from "../assets/images/imageBox.png";
import reviewImage from "../assets/icons/reviewImage.svg";
import {
  comma,
  endDayComment,
  getDayKorean,
} from "../constants/returnFunction";
import { GoodStatus } from "../gql/graphql";
import { IGood } from "../constants/types";

interface GoodsNiliverySNS {
  id: number | null;
  title: string | undefined;
  subTitle: string | null;
  image?: any;
  brand: string | undefined;
  ordered: number | null;
  desc: string | null;
  deliveryDate?: Date | null;
  endDate?: Date | null;
  classes?: string | null;
}

export const GoodsNiliverySNS: React.FC<GoodsNiliverySNS> = ({
  title,
  image,
  brand,
  desc,
  deliveryDate,
  endDate,
  classes,
}) => {
  const navigator = useNavigate();
  return (
    <div className="max-w-screen-fit mx-auto px-4 relative">
      <div className="flex flex-wrap justify-end bg-mainIcon goodsTag absolute left-0 -top-5 w-64 rounded-r-lg py-2 pr-4 z-10 gap-x-4">
        <div className="text-right text-white text-Xsmall font-bold">
          맛있는 족발을
          <br />
          놀라운 가격에!!
        </div>
        <div className="text-standard break:text-large text-white font-bold">
          {moment(deliveryDate).format("M월 D일") +
            "(" +
            getDayKorean(deliveryDate?.getDay()) +
            ")" +
            "요일까지"}
        </div>
      </div>
      <div className="goodsContent px-1">
        <div
          onClick={() => {
            // navigator(`/detail/${id}`);
            navigator(`/event`);
          }}
          className="relative rectangle detail-gradient  w-full aspect-[312/358] cursor-pointer"
        >
          <img
            src={image.length > 0 ? image[0]?.upload?.url : ""}
            // src={image}
            alt={title + "사진"}
            className="w-full h-full object-cover absolute left-0 top-0"
          />
          <div className="w-full h-full absolute left-0 top-0 pt-[22px] px-4 pb-4 flex flex-wrap flex-col justify-between z-10">
            <div className="text-white font-bold text-large break:text-Xlarge h-12 flex flex-wrap content-center justify-center">
              {title}
            </div>
            <div>
              <div className="text-white">
                <span className="align-middle inline-block px-[7px] py-[2.5px] bg-mainIcon text-small break:text-standardall rounded-md">
                  {endDayComment(endDate?.getTime())}
                </span>
                {/* <span className="ml-2 text-Xsmall inline-block align-middle">
                  {ordered}명이 주문했어요
                </span> */}
              </div>
              {/* <div className="mt-1 text-left text-white text-standard break:text-large py-1">
                {subTitle}
              </div> */}
            </div>
          </div>
        </div>
        <div className="texts pt-4">
          <div className="h-6 ellipsis1 overflow-hidden">
            <span className="text-large break:text-Xlarge font-bold">
              {classes}
            </span>
            <span className="text-standard break:text-large font-bold ml-4 text-shade1">
              {brand}
            </span>
          </div>
          <div className="py-1 mt-2 text-grayFont font-bold text-standard break:text-large h-14 overflow-hidden ellipsis2 ">
            {desc}
          </div>
        </div>
      </div>
    </div>
  );
};

export interface GoodsNiliveryIn {
  title: string | undefined;
  id: number;
  subTitle: string | null | undefined;
  image?: any;
  brand: IGood;
  desc?: string | null | undefined;
  status?: GoodStatus | undefined;
  deliveryDate?: Date;
  classes?: string;
  price: number | null;
  discountRate?: number | null;
  totalPrice?: number;
  brandImg?: string;
  service?: string | null;
  review?: number | null;
}

export const GoodsNiliveryIn: React.FC<GoodsNiliveryIn> = ({
  title,
  id,
  subTitle,
  image,
  brand,
  desc,
  status,
  classes,
  discountRate,
  price,
  service,
}) => {
  const navigator = useNavigate();

  return (
    <div className={`flex flex-col  relative`}>
      <div
        onClick={() => {
          status === GoodStatus.GoodSales && service !== "교통대"
            ? navigator(`/detail/${id}`)
            : alert("서비스 진행 기간이 아닙니다.");
        }}
        className={`relative rectangle ${
          status !== GoodStatus.GoodSales && "ready-gradient"
        }  w-[134px] h-[177px] aspect-[296/240] cursor-pointer `}
      >
        <img
          src={image.length > 0 ? image[0]?.upload?.url : imageBox}
          alt={title + "사진"}
          className="w-full h-full object-cover absolute left-0 top-0 "
        />
        {status === "goodReady" && (
          <div className="w-full h-full absolute left-0 top-0 pt-[22px] px-4 pb-4 flex flex-wrap flex-col justify-center z-10">
            <div>
              <div
                className={`text-white text-center h-10 ${
                  service === "김천혁신" ? "text-[24px] " : "text-[12px]"
                }  leading-6 font-bold p-2 ellipsis1 overflow-hidden`}
              >
                Coming Soon.
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="texts pt-2 flex-1 flex flex-col justify-between  w-[134px]">
        <div className="flex flex-warp flex-wrap content-center gap-x-2 ">
          <a
            onClick={
              () =>
                status === GoodStatus.GoodSales && service !== "교통대"
                  ? navigator(`/detail/${id}`)
                  : alert("서비스 진행 기간이 아닙니다.")
              // alert("판매 중인 상품이 아닙니다.")
            }
            className={`text-[14px] leading-4 hover:underline cursor-pointer`}
          >
            {title}
          </a>
        </div>
        <div
          className={`flex flex-wrap content-center justify-start gap-x-1
            `}
        >
          <div>
            {discountRate !== 0 && (
              <span className="align-middle line-through text-[11px] leading-4 text-[#979797]">
                {comma(price)}
              </span>
            )}
          </div>
          <div className="flex gap-x-1 w-full items-center text-[14px] leading-4">
            {discountRate !== 0 && (
              <span className="text-shade1 font-bold">{discountRate}%</span>
            )}
            <span className={`align-middle text-darkFont font-bold`}>
              {comma(calculate(Number(price), Number(discountRate)))} 원
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export const GoodsNiliveryEvent: React.FC<GoodsNiliveryIn> = ({
  title,
  id,
  image,
  brand,
  status,
  discountRate,
  price,
  service,
  review,
}) => {
  const navigator = useNavigate();

  let arrayLength = 0;
  if (brand.corporations) {
    arrayLength = brand.corporations.length;
  }

  return (
    <div className={`max-w-screen-fit mx-auto px-5 pt-3 relative w-full`}>
      <div
        onClick={() => {
          status === GoodStatus.GoodSales && service !== "교통대"
            ? navigator(`/detail/${id}`)
            : alert("서비스 진행 기간이 아닙니다.");
        }}
        className={`relative rectangle ${
          status !== GoodStatus.GoodSales && "ready-gradient"
        } rounded-2xl w-full aspect-[296/240] cursor-pointer `}
      >
        <img
          src={image.length > 0 ? image[0]?.upload?.url : imageBox}
          alt={title + "사진"}
          className="w-full h-full object-cover absolute left-0 top-0 "
        />
        {status === "goodReady" && (
          <div className="w-full h-full absolute left-0 top-0 pt-[22px] px-4 pb-4 flex flex-wrap flex-col justify-center z-10">
            <div>
              <div
                className={`text-white text-center h-10 ${
                  service === "김천혁신" ? "text-[24px] " : "text-[12px]"
                }  leading-6 font-bold p-2 ellipsis1 overflow-hidden`}
              >
                Coming Soon.
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="texts pt-2">
        <div className="flex flex-wrap content-center">
          <span
            className={`text-[13px] leading-4 text-[#60B0DB] flex flex-wrap content-center`}
          >
            {brand.corporations?.map((cor, index) => (
              <div key={`cor-${cor.name}`}>
                {`${cor.name}`}
                {`${index < arrayLength - 1 ? ", " : ""}`}
              </div>
            ))}
          </span>
        </div>
        <div className=" overflow-hidden flex flex-warp flex-wrap content-center gap-x-2">
          <a
            onClick={
              () =>
                status === GoodStatus.GoodSales && service !== "교통대"
                  ? navigator(`/detail/${id}`)
                  : alert("서비스 진행 기간이 아닙니다.")
              // alert("판매 중인 상품이 아닙니다.")
            }
            className={`ellipsis1 ${
              service === "이마트24"
                ? "text-base"
                : "text-base break:text-Xlarge"
            }  overflow-hidden hover:underline cursor-pointer`}
          >
            {title}
          </a>
        </div>
        <div
          className={`flex flex-wrap content-center justify-start items-center gap-x-1
            `}
        >
          {discountRate !== 0 && (
            <span className="text-shade1 font-bold text-[16px] leading-4">
              {discountRate}%
            </span>
          )}
          <span
            className={`align-middle text-darkFont   service === "이마트24"
                  ? "text-base"
                  : "text-large break:text-Xlarge"
              }  font-bold `}
          >
            {comma(calculate(Number(price), Number(discountRate)))} 원
          </span>

          <div>
            {discountRate !== 0 && (
              <span className="align-middle text-[13px] line-through text-small break:text-standardall text-[#979797] px-1">
                {comma(price)}
              </span>
            )}
          </div>
        </div>
        <div className="flex gap-x-1 items-center">
          <img src={reviewImage} />
          <span className="text-[#575757] text-xs">
            {review?.toString().padStart(2, "0") + "+"}
          </span>
        </div>
      </div>
    </div>
  );
};
